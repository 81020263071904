import React, { useState, useEffect } from 'react';

import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";
import style from './CustomInput.module.css';
import { COLORS } from './../../Constants/Colors/colors';
import { useSelector } from 'react-redux';
import { FONT_SIZES } from '../../Constants/Font/font_sizes';

const CustomInput = ({ errors, register, name, type = "text", placeholder, icon }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [innerType, setInnerType] = useState(type);
  const [isFocused, setIsFocused] = useState(false);
  const [isNotEmpty, setIsNotEmpty] = useState(false);

  useEffect(() => {
    const input = document.querySelector(`input[name="${name}"]`);
    setIsNotEmpty(input.value !== '');
  }, [name]);

  const handleBlur = (event) => {
    setIsFocused(false);
    setIsNotEmpty(event.target.value !== '');
  };

  return (
    <div className={style.inputContainer}>
      {errors[name] && <p className={style.errorMessage}>{errors[name].message}</p>}
      <div
        style={{
          borderColor: errors[name] ? COLORS.DANGER : isFocused || isNotEmpty ? COLORS.MAIN_COLOR : COLORS.SUB_FONT_COLOR,
          boxShadow:  errors[name] ? "none" : isFocused || isNotEmpty ? `0 0 5px 0 ${COLORS.MAIN_COLOR}` : "none",

        }}
      >
        {type === 'password' && <div className={style.showHide} onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}
          style={{ color: errors[name] ? COLORS.DANGER : isFocused || isNotEmpty ? COLORS.MAIN_COLOR : COLORS.SUB_FONT_COLOR }}
        >{innerType === 'password' ? <VscEye /> : <VscEyeClosed />}</div>}

        <input
          placeholder={placeholder}
          type={innerType}
          {...register(name)}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          style={{
            color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT,
            fontSize: FONT_SIZES.REGULAR
          }}
        />
        {icon && <div className={style.icon}
          style={{ color: errors[name] ? COLORS.DANGER : isFocused || isNotEmpty ? COLORS.MAIN_COLOR : COLORS.SUB_FONT_COLOR }}
        >{icon}</div>}
      </div>

    </div>
  );
};

export default CustomInput;
