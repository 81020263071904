export const COLORS = {
    MAIN_COLOR: "#3498DB",
    DANGER: "#EE3D3D",
    SUCCESS: "#49AF41",
    PENDING: "#FD9B20",
    NORMAL: "#BC5ED2",

    FIRST_BG_COLOR: {DARK:"#110F24", LIGHT:"#FEFEFE"},
    SECOND_BG_COLOR: {DARK:"#161429", LIGHT:"#F8F8FF"},

    MAIN_FONT_COLOR: {DARK: "#FEFEFE", LIGHT: "#110F24"},
    SUB_FONT_COLOR: "#9BAECB",
}
