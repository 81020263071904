import React, { useRef, useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Filler } from 'chart.js';
import { COLORS } from '../../Constants/Colors/colors';
import { useSelector } from 'react-redux';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);


const LineChart = ({ onlyLine, nolegend }) => {
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const initialData = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: [1902, 893, 1293, 723, 2341, 2113, 236, 578, 912, 2934, 345, 782],
                borderColor: COLORS.MAIN_COLOR,
                fill: true,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                display: nolegend ? "" : "none"
            },
        },
        elements: {
            line: {
                tension: 0.3,
                borderWidth: 1.5,
            },
            point: { radius: 0 },
        },
        scales: {
            x: onlyLine ? {
                grid: {
                    display: false, // Hide the x-axis grid lines
                },
                ticks: {
                    display: false, // Hide the x-axis labels
                },
            } : {
                grid: {
                    display: true, // Show the x-axis grid lines (default or conditionally shown)
                },
                ticks: {
                    display: true, // Show the x-axis labels (default or conditionally shown)
                },
            },
            y: onlyLine ? {
                grid: {
                    display: false, // Hide the y-axis grid lines
                },
                ticks: {
                    display: false, // Hide the y-axis labels
                },
            } : {
                grid: {
                    display: true, // Show the y-axis grid lines (default or conditionally shown)
                },
                ticks: {
                    callback: (value) => value, // Custom tick callback for y-axis labels
                },
            },
        },
    }


    const globalState = useSelector((state) => state?.global);
    const isDarkMode = globalState?.isDarkMode;

    const chartRef = useRef(null);

    const [chartData, setChartData] = useState(initialData);

    function createGradient(ctx) {
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);

        gradient.addColorStop(0, COLORS.MAIN_COLOR);
        gradient.addColorStop(1, isDarkMode ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 255, 255, 0)');

        return gradient;
    }

    useEffect(() => {
        const chart = chartRef.current;

        if (chart) {
            const ctx = chart.ctx;
            const newChartData = {
                ...initialData,
                datasets: initialData.datasets.map((dataset) => ({
                    ...dataset,
                    backgroundColor: createGradient(ctx),
                })),
            };

            setChartData(newChartData);
        }
    }, [isDarkMode]);

    return (
        <Line ref={chartRef} data={chartData} options={options} />
    );
}

export default LineChart;
