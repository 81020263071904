import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "../../Store/Reducer/globalReducer"
import arabic from "../../assets/flags/Egypt.png"
import english from "../../assets/flags/USA.png"
import style from "./LangTuggle.module.css"
import { COLORS } from '../../Constants/Colors/colors';

const LangTuggle = () => {
    const dispatch = useDispatch();

    const [language, setLanguage] = useState("en");

    useEffect(() => {
        const savedLanguage = localStorage.getItem("selectedLanguage");
        if (savedLanguage) {
            setLanguage(savedLanguage);
        }
    }, []);

    const handleLanguageChange = () => {
        const newLanguage = language === 'en' ? 'ar' : 'en';
        setLanguage(newLanguage);
        dispatch(setLang(newLanguage));
    };

    return (
        <div>
            <Toggle
                isChecked={language === 'en'}
                handleChange={handleLanguageChange}
                language={language}
            />
        </div>
    )
}

export default LangTuggle

const Toggle = ({ handleChange, isChecked, language }) => {
    const isDarkMode = useSelector(state => state.global.isDarkMode);

    return (
        <div className={style.container}>
            <input
                type="checkbox"
                id="check"
                className={style.toggle}
                checked={isChecked}
                onChange={handleChange}
            />
            <label
                htmlFor="check"
                style={{
                    color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT,
                    backgroundColor: isDarkMode? COLORS.FIRST_BG_COLOR.DARK : COLORS.FIRST_BG_COLOR.LIGHT,
                    padding: language === "en" ? "0 0 0 10px" : "0 10px 0 0"
                }}
            >
                <div className={style.image}>
                    <img src={language === 'en' ? english : arabic} alt="" />
                </div>
                {language === 'en' ? 'EN' : 'AR'}
            </label>
        </div>
    )
}
