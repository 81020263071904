import React, { useState } from 'react'
import style from "./Video.module.css"
import { ICONS } from '../../Constants/Icons/icons'
import Info from './Info/Info'
import Captions from './Captions/Captions'
import Chapters from './Chapters/Chapters'
import Moments from './Moments/Moments'
import Statistics from './Statistics/Statistics'
import Transcoding from './Transcoding/Transcoding'
import { COLORS } from '../../Constants/Colors/colors'
import { useSelector } from 'react-redux';

const Video = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    const TABS = { tab1: "info", tab2: "captions", tab3: "chapters", tab4: "moments", tab5: "statistics", tab6: "transcoding" }
    const [tab, setTab] = useState(TABS.tab1);


    return (
        <div className={style.container}>
            <div className={style.header}>
                <div>
                    <h1>Video Name</h1>
                    <p className={style.tag} style={{ color: COLORS.PENDING, backgroundColor: `${COLORS.PENDING}30` }}>Gaming</p>
                </div>
                <div>
                    <button style={{ color: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT, backgroundColor: COLORS.MAIN_COLOR }}>{ICONS.Download}</button>
                    <button style={{ color: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT, backgroundColor: COLORS.DANGER }}>{ICONS.Delete}</button>
                </div>
            </div>
            <div className={style.content}>
                <div>
                    <div className={style.video}></div>
                    <div className={style.tabsContainer}>
                        <div className={style.tabs}>
                            <ul className={isDarkMode && style.dark} style={{ backgroundColor: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>
                                <li className={(tab === TABS.tab1) && style.active} onClick={() => setTab(TABS.tab1)}>Info</li>
                                <li className={(tab === TABS.tab2) && style.active} onClick={() => setTab(TABS.tab2)}>Captions</li>
                                <li className={(tab === TABS.tab3) && style.active} onClick={() => setTab(TABS.tab3)}>Chapters</li>
                                <li className={(tab === TABS.tab4) && style.active} onClick={() => setTab(TABS.tab4)}>Moments</li>
                                <li className={(tab === TABS.tab5) && style.active} onClick={() => setTab(TABS.tab5)}>Statistics</li>
                                <li className={(tab === TABS.tab6) && style.active} onClick={() => setTab(TABS.tab6)}>Transcoding</li>
                            </ul>
                        </div>
                        <div className={style.page}>
                            {tab === TABS.tab1 && <Info />}
                            {tab === TABS.tab2 && <Captions />}
                            {tab === TABS.tab3 && <Chapters />}
                            {tab === TABS.tab4 && <Moments />}
                            {tab === TABS.tab5 && <Statistics />}
                            {tab === TABS.tab6 && <Transcoding />}
                        </div>
                    </div>
                </div>
                <div className={isDarkMode && style.dark}>
                    <div className={style.videoInfo}>
                        <h2>Video info</h2>
                        <div>
                            <h3>Title</h3>
                            <input className={style.videoName} style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }} type="text" placeholder='Video Name' />
                        </div>
                        <div>
                            <h3>Video ID</h3>
                            <CustomCoppedInput />
                        </div>
                    </div>
                    <div className={style.embed}>
                        <h2>Embed</h2>
                        <div className={style.checkContainer}>
                            <CustomToggleButton name="responsive" />
                            <CustomToggleButton name="autoplay" />
                            <CustomToggleButton name="preload" />
                            <CustomToggleButton name="loop" />
                            <CustomToggleButton name="muted" />
                        </div>
                        <CustomCoppedInput />
                    </div>
                    <div className={style.link}>
                        <h2>Link</h2>
                        <div>
                            <h3>Video ID</h3>
                            <CustomCoppedInput />
                        </div>
                        <div>
                            <h3>HLS Playlist URL</h3>
                            <CustomCoppedInput />
                        </div>
                        <div>
                            <h3>Direct Play URL</h3>
                            <CustomCoppedInput />
                        </div>
                        <div>
                            <h3>Thumbnail URL</h3>
                            <CustomCoppedInput />
                        </div>
                        <div>
                            <h3>Preview Animation URL (WebP)</h3>
                            <CustomCoppedInput />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Video



const CustomCoppedInput = ({ value }) => {
    const [isCopied, setIsCopied] = useState(false);

    value = "201c44e3-c032-451e-a6d7-067aad0d3605";

    const handleCopyClick = () => {
        navigator.clipboard.writeText(value).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        });
    };

    return (
        <div className={style.inputContainer}>
            <input type="text" value={value} disabled />
            <div className={style.copyIcon} onClick={handleCopyClick} style={{ color: isCopied && COLORS.SUCCESS }}>
                {isCopied ? ICONS.Check : ICONS.Copy}
            </div>
        </div>
    );
};

const CustomToggleButton = ({ handleChange, name, initialChecked = false }) => {
    const [isChecked, setIsChecked] = useState(initialChecked);

    const onChange = (e) => {
        setIsChecked(e.target.checked);
        if (handleChange) {
            handleChange(e.target.checked);
        }
    };

    return (
        <div className={style.toggleContainer}>
            <input
                type="checkbox"
                id={name}
                className={style.toggle}
                checked={isChecked}
                onChange={onChange}
            />
            
            <label htmlFor={name} className={style.label}>
                <div className={style.icon} style={{ backgroundColor: isChecked ? COLORS.MAIN_COLOR : COLORS.SUB_FONT_COLOR }}>
                    {isChecked ? ICONS.Check : ICONS.Close}
                </div>
                <h3>{name}</h3>
            </label>
        </div>
    );
};
