import React from 'react';
import { useSelector } from 'react-redux';
import style from "./SideSection.module.css";
import { COLORS } from '../../Constants/Colors/colors';
import { IoCloseCircle } from "react-icons/io5";

const BackDrop = ({ closeModal }) => {
    return <div className={style.backdrop} onClick={closeModal}></div>
}

const Overlay = ({ body, closeModal }) => {
    const globalState = useSelector((state) => state?.global);
    const isDarkMode = globalState?.isDarkMode;

    return (
        <div
            className={`${style.overlay}`}
            style={{ backgroundColor: isDarkMode ? COLORS.FIRST_BG_COLOR.DARK : COLORS.FIRST_BG_COLOR.LIGHT }}
        >
            <div className={style.closeButton}
                style={{ backgroundColor: isDarkMode ? COLORS.FIRST_BG_COLOR.DARK : COLORS.FIRST_BG_COLOR.LIGHT }}
            >
                <IoCloseCircle onClick={closeModal} />
            </div>
            {body}
        </div>
    );
}

const SideSection = ({ children, isAppear, onClose }) => {
    return (
        <>
            <div className={`${style.container} ${isAppear && style.slide}`}>
                <BackDrop closeModal={onClose} />
                <Overlay closeModal={onClose} body={children} />
            </div>
        </>
    );
}

export default SideSection;
