import { z } from "zod";

export const signupSchema = (lang) => {
    return z.object({
        name: z
            .string()
            .nonempty(lang === 'ar' ? "اسم المستخدم مطلوب" : "Username is required")
            .min(3, lang === 'ar' ? "الاسم  يجب أن تكون 3 حروف على الأقل" : "Username must be at least 3 characters long")
            .regex(/^[a-zA-Z]+$/, lang === 'ar' ? "اسم المستخدم غير صالح" : "Username is invalid"),

        email: z
            .string()
            .nonempty(lang === 'ar' ? "البريد االإلكتروني مطلوب" : "Email is required")
            .regex(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, lang === 'ar' ? "البريد االإلكتروني غير صالح" : "Email is invalid"),

        phone: z
            .string()
            .nonempty(lang === 'ar' ? "رقم الهاتف مطلوب" : "Phone is required")
            .regex(/^(011|010|012|015)\d{8}$/, lang === 'ar' ? "رقم الهاتف غير صالح" : "Phone is invalid"),

        password: z
            .string()
            .nonempty(lang === 'ar' ? "كلمة المرور مطلوبة" : "Password is required")
            .min(8, lang === 'ar' ? "كلمة المرور يجب أن تكون 8 حروف على الأقل" : "Password must be at least 8 characters long"),

        confirmPassword: z
            .string()
            .nonempty(lang === 'ar' ? "تأكيد كلمة المرور مطلوب" : "Confirm password is required")
            .min(8, lang === 'ar' ? "كلمة المرور يجب أن تكون 8 حروف على الأقل" : "Password must be at least 8 characters long"),

    }).refine((data) => data.password === data.confirmPassword, {
        path: ['confirmPassword'],
        message: lang === 'ar' ? "كلمتين المرور غير متطابقتين" : "Passwords do not match",
    });
};
