import React from 'react'
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import style from "./SideBar.module.css"
import { useSelector } from 'react-redux';
import { ICONS } from '../../Constants/Icons/icons';
import { COLORS } from '../../Constants/Colors/colors';

const SideBar = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    return (
        <aside className={`${style.container}`}>
            <CustomLink to="/home" className={style.overView}>
                {lang === 'ar' ? "الملخص" : "Overview"}
                {ICONS.Home}
            </CustomLink>

            <ul style={{ backgroundColor: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>
                <CustomLink to="/stream">
                    {ICONS.Stream}
                    {lang === 'ar' ? "ستريم" : "Stream"}
                </CustomLink>

                <CustomLink to="/manage-videos">
                    {ICONS.ManageVideos}
                    {lang === 'ar' ? "إدارة الفيديوهات" : "Manage Videos"}
                </CustomLink>
                <CustomLink to="/player">
                    {ICONS.Player}
                    {lang === 'ar' ? "المشغل" : "Player"}
                </CustomLink>
                <CustomLink to="">
                    {ICONS.Advertising}
                    {lang === 'ar' ? "التسويق" : "Advertising"}
                </CustomLink>
                <CustomLink to="">
                    {ICONS.Encoding}
                    {lang === 'ar' ? "الترميز" : "Encoding"}
                </CustomLink>
                <CustomLink to="">
                    {ICONS.Transcribing}
                    {lang === 'ar' ? "النسخ" : "Transcribing"}
                </CustomLink>
                <CustomLink to="">
                    {ICONS.Security}
                    {lang === 'ar' ? "الأمان" : "Security"}
                </CustomLink>
                <CustomLink to="">
                    {ICONS.Delivery}
                    {lang === 'ar' ? "التسليمات" : "Delivery"}
                </CustomLink>
                <CustomLink to="">
                    {ICONS.Storage}
                    {lang === 'ar' ? "التخزين" : "Storage"}
                </CustomLink>
                <CustomLink to="">
                    {ICONS.Statistics}
                    {lang === 'ar' ? "الإحصائيات" : "Statistics"}
                </CustomLink>
            </ul>

        </aside>
    )
}

export default SideBar


const CustomLink = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    return (
        <Link to={to} {...props}>
            <li className={isActive ? style.active : ""}>
                {children}
            </li>
        </Link>
    );
};