import { FaHome } from "react-icons/fa";
import { CiStreamOn } from "react-icons/ci";
import { MdVideoSettings } from "react-icons/md";
import { TbPlayerPlay } from "react-icons/tb";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { MdAlternateEmail } from "react-icons/md";
import { FaCode } from "react-icons/fa6";
import { PiSoundcloudLogoBold } from "react-icons/pi";
import { BiSolidLock } from "react-icons/bi";
import { TbLocation } from "react-icons/tb";
import { GrStorage } from "react-icons/gr";
import { LuBarChart3 } from "react-icons/lu";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";
import { MdPassword } from "react-icons/md";
import { FaUser } from "react-icons/fa6";
import { TfiMoreAlt } from "react-icons/tfi";
import { FaClock } from "react-icons/fa6";
import { MdViewStream } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import { PiPhoneFill } from "react-icons/pi";
import { FaDownload } from "react-icons/fa6";
import { FaUpload } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { IoIosCopy } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";


export const ICONS = {
    Home: <FaHome />,
    Stream: <CiStreamOn />,
    ManageVideos: <MdVideoSettings />,
    Player: <TbPlayerPlay />,
    Advertising: <HiOutlineSpeakerphone />,
    Encoding: <FaCode />,
    Transcribing: <PiSoundcloudLogoBold />,
    Security: <BiSolidLock />,
    Delivery: <TbLocation />,
    Storage: <GrStorage />,
    Statistics: <LuBarChart3 />,
    Email: <MdAlternateEmail />,
    Password: <MdPassword />,
    CloseEye: <VscEyeClosed />,
    OpenEye: <VscEye />,
    User: <FaUser />,
    More: <TfiMoreAlt />,
    Clock: <FaClock />,
    VideoStorage: <MdViewStream />,
    View: <IoMdEye />,
    DownArrow: <FaAngleDown />,
    Phone: <PiPhoneFill />,
    Download: <FaDownload />,
    Upload: <FaUpload />,
    Delete: <MdDelete />,
    Copy: <IoIosCopy />,
    Check: <FaCheck />,
    Close: <IoClose />,
};