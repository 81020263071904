import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../assets/loading.json'; 

const LoadingAnimation = () => {
    return (
        <div>
            <Lottie 
                animationData={animationData} 
                loop={true} 
                style={{ width: 30, height: 30 }}
            />
        </div>
    );
};

export default LoadingAnimation;
