import React from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector } from 'react-redux';
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "../../Constants/base_url";

import { loginSchema } from './../../Schema/login.schema';
import CustomInput from './../CustomInput/CustomInput';
import style from "./Login.module.css";
import { COLORS } from '../../Constants/Colors/colors';
import { ICONS } from './../../Constants/Icons/icons';
import { FcGoogle } from "react-icons/fc";
import CustomHeader from './../CustomHeader/CustomHeader';
import CustomButton from '../../Shared/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';

const Login = ({ onClick }) => {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const schema = loginSchema(lang);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const { mutate, isError, error, isPending, isLoading } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}auth/login`, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      })
      .catch(err => {
        console.error("Axios network error:", err);
        throw err; 
      });
    },
    onSuccess: (data) => {
      console.log("Logged in successfully", data);
      // navigate("/home");
    },
    onError: (error) => {
      console.log("Login failed:", error);
    },
  });

  const onSubmit = (data) => {
    console.log("form data: ", data);
    mutate(data);
  };

  const handleGoogleLogin = () => {
    window.location.href = `${api_url}auth/google/redirect`;
  };

  if (isError) {
    console.log("axios error", error);
  }

  return (
    <div className={style.container}>
      <CustomHeader size={50}>
        <h1 style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_COLOR }}>
          {lang === 'ar' ? "تسجيل الدخول" : "Login"}
        </h1>
      </CustomHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          placeholder={lang === 'ar' ? 'البريد الإلكتروني' : 'Email'}
          errors={errors}
          register={register}
          name='email'
          icon={ICONS.Email}
        />

        <CustomInput
          placeholder={lang === 'ar' ? 'كلمة السر' : 'Password'}
          type="password"
          errors={errors}
          register={register}
          name='password'
          icon={ICONS.Password}
        />
        <CustomButton type="submit" isLoading={isLoading}>{lang === 'ar' ? "تسجيل الدخول" : "Login"}</CustomButton>
      </form>
      <p>{lang === 'ar' ? "ليس لديك حساب؟" : "Don't have an account?"} <span onClick={onClick}>{lang === 'ar' ? "انشاء حساب" : "Sign Up"}</span>.</p>
      <div className={style.continueWith}>{lang === 'ar' ? "او سجل الدخول بواسطة" : "Or continue with"}</div>
      <div className={style.icons}>
        <FcGoogle onClick={handleGoogleLogin} style={{ cursor: 'pointer' }} />
      </div>
    </div>
  );
}

export default Login;
