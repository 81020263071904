import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setDarkMode } from '../../Store/Reducer/globalReducer';
import style from "./ModeTuggle.module.css";

const ModeTuggle = () => {
    const dispatch = useDispatch();
    const isDarkMode = useSelector(state => state.global.isDarkMode);

    const handleModeChange = () => {
        dispatch(setDarkMode(!isDarkMode));
    };

    return (
        <div>
            <Toggle
                handleChange={handleModeChange}
                isChecked={isDarkMode}
            />
        </div>
    );
};

export default ModeTuggle;

const Toggle = ({ handleChange, isChecked }) => {
    return (
        <div className={style.container}>
            <input
                type="checkbox"
                id="checkMode"
                className={style.toggle}
                checked={isChecked}
                onChange={handleChange}
            />
            <label htmlFor="checkMode" className={style.label} title={isChecked ? "Dark Mode" : "Light Mode"}>
            </label>
        </div>
    );
};
