import React from 'react'
import LibraryCard from '../../Components/LibraryCard/LibraryCard'
import style from "./Stream.module.css"

const Stream = () => {
    return (
        <main className='pageSection'>
            <div className={style.cardsContainer}>
                <LibraryCard />
                <LibraryCard />
                <LibraryCard />
                <LibraryCard />
                <LibraryCard />
                <LibraryCard />
                <LibraryCard />
                <LibraryCard />
                <LibraryCard />
                <LibraryCard />
            </div>
        </main>
    )
}

export default Stream