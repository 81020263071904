import React from 'react'
import style from "./VideoCard.module.css"
import { ICONS } from '../../Constants/Icons/icons'
import { useSelector } from 'react-redux';
import { COLORS } from '../../Constants/Colors/colors';
import { FONT_SIZES } from '../../Constants/Font/font_sizes';
import { Link } from 'react-router-dom';

const VideoCard = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    return (
        <Link to="/video">
            <div className={style.cardContainer} style={{ backgroundColor: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>
                <div className={style.videoContainer}>
                    <div className={style.video}></div>
                    <div>
                        <div className={style.views} style={{ color: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>
                            0 {ICONS.View}
                        </div>
                        <div className={style.moreIcon}>
                            {ICONS.More}
                        </div>
                    </div>
                </div>
                <div className={style.tag} style={{ backgroundColor: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT, fontSize: FONT_SIZES.SMALL }}>
                    uploading
                </div>
                <div className={style.content}>
                    <h4 style={{ fontSize: FONT_SIZES.REGULAR, color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>Video Name</h4>
                    <div>
                        <div style={{ fontSize: FONT_SIZES.NORMAL }}>{ICONS.Clock} <p>00:00:00</p></div>
                        <div style={{ fontSize: FONT_SIZES.NORMAL }}>{ICONS.VideoStorage} <p>0 Bytes</p></div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default VideoCard