import React, { Children } from 'react'
import style from "./CustomSelect.module.css"
import { ICONS } from './../../Constants/Icons/icons';
import { useSelector } from 'react-redux';
import { COLORS } from './../../Constants/Colors/colors';
import { FONT_SIZES } from './../../Constants/Font/font_sizes';

const CustomSelect = ({ children }) => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    return (
        <div className={style.select} style={{ fontSize: FONT_SIZES.REGULAR }}>
            <div>
                Select an item
                {ICONS.DownArrow}
            </div>
            <div>
                <ul style={{ color: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>
                    {children}
                </ul>
            </div>
        </div>
    )
}

export default CustomSelect