import React from 'react'
import { useSelector } from 'react-redux';
import style from "./Header.module.css"
import { COLORS } from '../../Constants/Colors/colors';
import logo from "../../assets/logo/7p.png"
import { IoIosArrowBack } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import ModeTuggle from '../ModeTuggle/ModeTuggle';
import LangTuggle from '../LangTuggle/LangTuggle';
import { FONT_SIZES } from '../../Constants/Font/font_sizes';
import user from "../../assets/user.jpg"

const Header = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  return (
    <header className={style.container} style={{ backgroundColor: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>
      <section className={style.sideSection}>
        <div>
          <div className={style.logo}><img src={logo} alt="" /></div>
          <p style={{ fontSize: FONT_SIZES.LARGE, color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>7P</p>
        </div>

        <div className={style.icon} style={{ backgroundColor: isDarkMode ? COLORS.FIRST_BG_COLOR.DARK : COLORS.FIRST_BG_COLOR.LIGHT, color: COLORS.SUB_FONT_COLOR }}>
          <IoIosArrowBack />
        </div>
      </section>

      <section className={style.searchContainer}>
        <div style={{ backgroundColor: isDarkMode ? COLORS.FIRST_BG_COLOR.DARK : COLORS.FIRST_BG_COLOR.LIGHT, color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>
          <FiSearch />
          <input type="text" style={{ fontSize: FONT_SIZES.REGULAR, color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }} placeholder='Search...' />
        </div>
      </section>
      <section className={style.rightSection}>
        <div className={style.profile}>
          <div className={style.image}><img src={user} alt="" /></div>
          <p style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT, fontSize: FONT_SIZES.REGULAR }}>Alexandar georgiev</p>
        </div>
        <ModeTuggle />
        <LangTuggle />
      </section>
    </header>
  )
}

export default Header