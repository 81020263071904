import { useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { ROLES } from './Constants/Roles/Roles';

import Header from './Components/Header/Header';
import Landing from './Pages/Landing/Landing';
import Auth from './Pages/Auth/Auth';
import ProtectedRoute from './Config/ProtectedRoute';
import Overview from './Pages/Overview/Overview';
import SideBar from './Components/SideBar/SideBar';
import { COLORS } from './Constants/Colors/colors';
import { useSelector } from 'react-redux';
import Stream from './Pages/Stream/Stream';
import ManageVideos from './Pages/ManageVideos/ManageVideos';
import Player from './Pages/Player/Player';
import Video from './Pages/Video/Video';


const App = () => {
  const isDarkMode = useSelector((state) => state?.global.isDarkMode);

  const location = useLocation();

  useEffect(() => {
    window.localStorage.setItem('loggedIn', 'true');
    window.localStorage.setItem('userType', ROLES.SUBSCRIBER);
  }, []);

  const isLoggedIn = window.localStorage.getItem('loggedIn') === 'true';
  const userType = window.localStorage.getItem('userType');

  const hideHeaderPaths = ['/login'];

  return (
    <div className='container' style={{ backgroundColor: isDarkMode ? COLORS.FIRST_BG_COLOR.DARK : COLORS.FIRST_BG_COLOR.LIGHT }}>
      {!hideHeaderPaths.includes(location.pathname) && (
        <>
          <Header isLoggedIn={isLoggedIn} userType={userType} />
        </>
      )}
      <div className='sub-container'>
        {!hideHeaderPaths.includes(location.pathname) && (
          <>
            <SideBar isLoggedIn={isLoggedIn} userType={userType} />
          </>
        )}
        <Routes>
          {!isLoggedIn ? (
            <>
              <Route index element={<Landing />} />
              <Route path="/login" element={<Auth />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          ) : (
            <Route element={<ProtectedRoute />}>
              {userType === ROLES.SUBSCRIBER ? (
                <>
                  <Route path="/home" element={<Overview />} />
                  <Route path="/stream" element={<Stream />} />
                  <Route path="/manage-videos" element={<ManageVideos />} />
                  <Route path="/player" element={<Player />} />
                  <Route path="/video" element={<Video />} />
                  <Route path="*" element={<Navigate to="/home" />} />
                </>
              ) : (
                <Route path="*" element={<Navigate to="/login" />} />
              )}
            </Route>
          )}
        </Routes>
      </div>
    </div>
  );
}

export default App;
