import React from 'react'
import style from "./CustomButton.module.css"
import { FONT_SIZES } from '../../Constants/Font/font_sizes'
import { useSelector } from 'react-redux';
import { COLORS } from '../../Constants/Colors/colors';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';

const CustomButton = ({ children, type = 'button', onClick, isLoading }) => {
  const isDarkMode = useSelector((state) => state?.global.isDarkMode);
  return (
    <button className={style.button} type={type} onClick={onClick} style={{ fontSize: FONT_SIZES.REGULAR, color: isDarkMode ? COLORS.MAIN_FONT_COLOR.LIGHT : COLORS.MAIN_FONT_COLOR.DARK }}>
      {isLoading ? <LoadingAnimation /> : children}
    </button>
  )
}

export default CustomButton