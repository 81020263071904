import React, {useState} from 'react'
import style from "./Overview.module.css"
import VideoCard from '../../../Components/VideoCard/VideoCard'
import { useSelector } from 'react-redux';
import { FONT_SIZES } from '../../../Constants/Font/font_sizes';
import { COLORS } from '../../../Constants/Colors/colors';
import CollectionCard from '../../../Components/CollectionCard/CollectionCard';
import SideSection from './../../../Shared/SideSection/SideSection';
import CollectionVideos from './../Collections/CollectionVideos/CollectionVideos';

const Overview = ({ onClickCollections, onClickVideos }) => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    const [selectedCollection, setSelectedCollection] = useState(false);


    return (
        <>
            <div className={style.container}>
                <section>
                    <div className={style.details}>
                        <h4 style={{ fontSize: FONT_SIZES.MEDIUM, color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>Latest Collections</h4>
                        <div>
                            <p style={{ color: COLORS.SUB_FONT_COLOR }}>1 Collections</p>
                            <div className={style.seeAllButton} onClick={onClickCollections}>See All</div>
                        </div>
                    </div>
                    <div className={style.subContainer}>
                        <CollectionCard
                            onClick={() => setSelectedCollection(true)} //by id

                        />
                    </div>
                </section>
                <section>
                    <div className={style.details}>
                        <h4 style={{ fontSize: FONT_SIZES.MEDIUM, color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>Latest Videos</h4>
                        <div>
                            <p style={{ color: COLORS.SUB_FONT_COLOR }}>442.59 MB of Videos</p>
                            <div>
                                <p>6 Videos</p>
                                <div className={style.seeAllButton} onClick={onClickVideos}>See All</div>
                            </div>
                        </div>
                    </div>
                    <div className={style.subContainer}>
                        <VideoCard />
                        <VideoCard />
                        <VideoCard />
                        <VideoCard />
                        <VideoCard />
                        <VideoCard />
                    </div>
                </section>
            </div>
            <SideSection isAppear={selectedCollection} onClose={() => setSelectedCollection(false)}>
                <CollectionVideos />
            </SideSection>
        </>
    )
}

export default Overview