import React from 'react'
import style from "./ChartCard.module.css"
import { FaLongArrowAltRight } from 'react-icons/fa';
import { COLORS } from '../../Constants/Colors/colors';
import { useSelector } from 'react-redux';
import LineChart from '../LineChart/LineChart';

const ChartCard = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    return (
        <div className={style.card}>
            <div className={style.cardHeader}>
                <p>Bandwidth Used</p>
                <div>
                    <h4 style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>253.74 TB</h4>
                    <div className={style.precentage}>
                        <FaLongArrowAltRight style={{ transform: "rotate(90deg)" }} />
                        -11.3%
                    </div>
                </div>
            </div>
            <div className={style.chart}><LineChart onlyLine nolegend /></div>
        </div>
    )
}

export default ChartCard