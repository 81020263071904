import React from 'react'
import style from "./Ball.module.css"
import { useSelector } from 'react-redux';

const Ball = ({width}) => {
  const isDarkMode = useSelector((state) => state?.global.isDarkMode);

  return (
    <div className={`${style.ball} ${isDarkMode ? "" : style.light}`} style={{width: width, height: width}}></div>
  )
}

export default Ball