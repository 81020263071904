import React from 'react'
import style from "./CustomHeader.module.css"
import { useSelector } from 'react-redux';

const CustomHeader = ({ size, children }) => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;

    return (
        <div className={style.container}>
            <div className={style.dots}>
                <div style={{ width: `${size - 15}px`, height: `${size - 15}px` }}></div>
                <div style={{ width: `${size}px`, height: `${size}px`, transform: `translateX(${lang === "ar" ? "-5px":"5px"})` }}></div>
            </div>
            {children}
        </div>
    )
}

export default CustomHeader