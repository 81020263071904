import React, { useState } from 'react'
import style from "./CollectionCard.module.css"
import { ICONS } from './../../Constants/Icons/icons';
import { BiSolidVideos } from "react-icons/bi";
import { FONT_SIZES } from './../../Constants/Font/font_sizes';
import { useSelector } from 'react-redux';
import { COLORS } from './../../Constants/Colors/colors';

const CollectionCard = ({onClick}) => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;


    return (
        <div className={style.cardContainer} onClick={onClick} style={{ backgroundColor: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>
            <div className={style.videoContainer}>
                <div className={style.video}></div>
                <div>
                    <div className={style.moreIcon}>
                        {ICONS.More}
                    </div>
                </div>
            </div>

            <div className={style.content}>
                <h4 style={{ fontSize: FONT_SIZES.REGULAR, color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>Collection Name</h4>
                <div>
                    <div style={{ fontSize: FONT_SIZES.NORMAL }}><BiSolidVideos /><p>0</p></div>
                    <div style={{ fontSize: FONT_SIZES.NORMAL }}>{ICONS.VideoStorage} <p>0 Bytes</p></div>
                </div>
            </div>
        </div>
    )
}

export default CollectionCard