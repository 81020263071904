import React from 'react'
import style from "./CollectionVideos.module.css"
import VideoCard from '../../../../Components/VideoCard/VideoCard'
import CustomButton2 from '../../../../Shared/CustomButton2/CustomButton2'

const CollectionVideos = ({ collectionID }) => {
    return (
        <div className={style.collectionVideosContainer}>
            <div className={style.header}>
                <h1>Collection Name</h1>
                <CustomButton2>Upload Video</CustomButton2>
            </div>
            <div className={style.videosContainer}>
                <VideoCard />
                <VideoCard />
                <VideoCard />
            </div>
        </div>
    )
}

export default CollectionVideos