import React from 'react'
import ReactDom from 'react-dom';
import { useSelector } from 'react-redux';

import style from "./Modal.module.css"
import { COLORS } from '../../Constants/Colors/colors';
import { IoCloseCircle } from "react-icons/io5";



const BackDrop = ({ closeModal }) => {
  return <div className={style.backdrop} onClick={closeModal}></div>
}

const Overlay = ({ body, closeModal }) => {
  const globalState = useSelector((state) => state?.global);
  const isDarkMode = globalState?.isDarkMode;

  return <div
    className={style.overlay}
    style={{ backgroundColor: isDarkMode ? COLORS.FIRST_BG_COLOR.DARK : COLORS.FIRST_BG_COLOR.LIGHT }}
  >
    <div className={style.closeButton}>
      <IoCloseCircle onClick={closeModal} />
    </div>
    {body}
  </div>
}

const Modal = ({ children, isAppear, onClose }) => {

  return (
    isAppear &&
    <>
      {ReactDom.createPortal(
        <>
          <BackDrop closeModal={onClose} />
          <Overlay closeModal={onClose} body={children} />
        </>

        , document.getElementById("modal")
      )}
    </>
  )
}

export default Modal