import { z } from "zod";


export const loginSchema = (lang) => {

    return z.object({
        email: z
            .string()
            .nonempty(lang === 'ar' ? "البريد االإلكتروني مطلوب" : "Email is required")
            .regex(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, lang === 'ar' ? "البريد االإلكتروني غير صالح" : "Email is invalid"),


        password: z
            .string()
            .nonempty(lang === 'ar' ? "كلمة المرور مطلوبة" : "Password is required")
            .min(8, lang === 'ar' ? "كلمة المرور يجب أن تكون 8 حروف على الأقل" : "Password must be at least 6 characters long"),
    });
};
