import React, { Children } from 'react'
import style from "./CustomAddButton.module.css"
import { FONT_SIZES } from './../../Constants/Font/font_sizes';
import { FaAngleDown } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { COLORS } from './../../Constants/Colors/colors';

const CustomAddButton = ({children, onClick}) => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;
    
    return (
        <button className={style.uploadVideoButton} onClick={onClick}>
            <p style={{ fontSize: FONT_SIZES.NORMAL }}>{children}</p>
            <div className={style.downArrowIcon} style={{ color: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>
                <FaAngleDown />
            </div>
        </button>
    )
}

export default CustomAddButton