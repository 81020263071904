import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import s from "./Auth.module.css"
import { FaArrowLeft } from "react-icons/fa6";
import SliderComponent from './../../Components/Slider/Slider';
import { FONT_SIZES } from '../../Constants/Font/font_sizes';
import LangTuggle from './../../Components/LangTuggle/LangTuggle';
import ModeTuggle from './../../Components/ModeTuggle/ModeTuggle';
import Ball from './../../Components/Ball/Ball';
import Login from './../../Components/Login/Login';
import Signup from './../../Components/Signup/Signup';
import { COLORS } from '../../Constants/Colors/colors';

const Auth = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    const [login, toggle] = useState(true);
    return (
        <>
            <main className={s.mainContainer} style={{ backgroundColor: isDarkMode ? COLORS.FIRST_BG_COLOR.DARK : COLORS.FIRST_BG_COLOR.LIGHT }}>
                <div className={s.modes}>
                    <ModeTuggle />
                    <LangTuggle />
                </div>
                <section className={s.dataSection} style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                    {/***************** display in mobile *****************/}
                    <div className={s.ballsContainer}>
                        <Ball width={"100px"} />
                        <Ball width={"75px"} />
                        <Ball width={"50px"} />
                        <Ball width={"25px"} />
                    </div>
                    {/*****************************************************/}

                    <div className={`${s.formContainer} ${!login ? s.slide : ""} ${lang === "ar" ? s.arabicleft : ""}`} style={{ left: lang === 'ar' ? "30%" : '50%' }}>
                        <Login onClick={() => toggle(false)} />
                        <Signup onClick={() => toggle(true)} />
                    </div>
                </section>
                <div className={s.slideButton}>
                    <div className={`${s.icon} ${!login ? s.rotate : ""}`} onClick={() => toggle(!login)}><FaArrowLeft /></div>
                </div>
                <section className={s.viewSection}>
                    <div className={s.logo}>
                        <div className={s.ballsContainer}>
                            <Ball width={"100px"} />
                            <Ball width={"75px"} />
                            <Ball width={"50px"} />
                            <Ball width={"25px"} />
                        </div>
                    </div>
                    <div className={`${s.slider} ${isDarkMode ? s.dark : ""}`}>
                        <SliderComponent>
                            <div className={s.content}>
                                <h1
                                    style={{ fontSize: FONT_SIZES.LARGE }}
                                >
                                    New 7P Dashboard is live!
                                </h1>

                                <p
                                    style={{ fontSize: FONT_SIZES.REGULAR }}
                                >
                                    We are excited to announce the launch of our new dashboard - with a fresh, clean, intuitive interface that is great to look at and easy to use.
                                </p>
                            </div>
                            <div className={s.content}>
                                <h1
                                    style={{ fontSize: FONT_SIZES.LARGE }}
                                >
                                    New 7P Dashboard is live!
                                </h1>

                                <p
                                    style={{ fontSize: FONT_SIZES.REGULAR }}
                                >
                                    We are excited to announce the launch of our new dashboard - with a fresh, clean, intuitive interface that is great to look at and easy to use.
                                </p>
                            </div>
                            <div className={s.content}>
                                <h1
                                    style={{ fontSize: FONT_SIZES.LARGE }}
                                >
                                    New 7P Dashboard is live!
                                </h1>

                                <p
                                    style={{ fontSize: FONT_SIZES.REGULAR }}
                                >
                                    We are excited to announce the launch of our new dashboard - with a fresh, clean, intuitive interface that is great to look at and easy to use.
                                </p>
                            </div>
                        </SliderComponent>
                    </div>
                </section>
            </main>
        </>

    )
}

export default Auth