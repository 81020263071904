import React, { useState } from 'react';
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "../../Constants/base_url";
import CustomHeader from './../CustomHeader/CustomHeader';
import { useSelector } from 'react-redux';
import { COLORS } from './../../Constants/Colors/colors';
import CustomInput from './../CustomInput/CustomInput';
import { ICONS } from './../../Constants/Icons/icons';
import CustomButton from './../../Shared/CustomButton/CustomButton';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import style from "./Signup.module.css";
import { signupSchema } from './../../Schema/signup.schema';
import { FcGoogle } from 'react-icons/fc';
import VerifyEmail from './VerifyEmail/VerifyEmail';

const Signup = ({ onClick }) => {
  const [email, setEmail] = useState(null);
  const [showVerification, setShowVerification] = useState(false);

  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const schema = signupSchema(lang);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });

  const { mutate, isError, error, isLoading } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}auth/register`, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: (data) => {
      console.log("Registration successful", data);
      setEmail(data.email);
      setShowVerification(true);
    },
    onError: (error) => {
      console.error("Registration failed:", error);
    }
  });

  const onSubmit = (data) => {
    delete data.confirmPassword;
    data.role = 'user';
    console.log("Form Data:", data);
    mutate(data);
  };

  const handleGoogleLogin = () => {
    window.location.href = `${api_url}auth/google/redirect`;
  };

  const handleVerificationSuccess = () => {
    console.log("Email verified successfully!");
    onClick()
  };

  return (
    <div className={style.container}>
      {showVerification ? (
        // <></>
        <VerifyEmail email={email} onSuccess={handleVerificationSuccess} />
      ) : (
        <div>
          <CustomHeader size={50}>
            <h1 style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_COLOR }}>
              {lang === 'ar' ? "انشاء حساب" : "Sign Up"}
            </h1>
          </CustomHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CustomInput
              placeholder={lang === 'ar' ? 'اسم المستخدم' : 'Username'}
              errors={errors}
              register={register}
              name='name'
              icon={ICONS.User}
            />

            <CustomInput
              placeholder={lang === 'ar' ? 'الهاتف' : 'Phone'}
              errors={errors}
              register={register}
              name='phone'
              icon={ICONS.Phone}
            />

            <CustomInput
              placeholder={lang === 'ar' ? 'البريد الإلكتروني' : 'Email'}
              errors={errors}
              register={register}
              name='email'
              icon={ICONS.Email}
            />

            <span>
              <CustomInput
                placeholder={lang === 'ar' ? 'كلمة السر' : 'Password'}
                type="password"
                errors={errors}
                register={register}
                name='password'
                icon={ICONS.Password}
              />

              <CustomInput
                placeholder={lang === 'ar' ? 'تأكيد كلمة السر' : 'Confirm password'}
                type="password"
                errors={errors}
                register={register}
                name='confirmPassword'
                icon={ICONS.Password}
              />
            </span>

            <CustomButton type="submit" disabled={isLoading} isLoading={isLoading}>
              {isLoading ? "Submitting..." : lang === 'ar' ? "انشاء حساب" : "Sign Up"}
            </CustomButton>
          </form>
          <p>{lang === 'ar' ? "بالفعل لديك حساب؟" : "Already have an account?"} <span onClick={onClick}>{lang === 'ar' ? "تسجيل الدخول" : "Login"}</span>.</p>
          <div className={style.continueWith}>{lang === 'ar' ? "او انشئ حساب بواسطة" : "Or continue with"}</div>
          <div className={style.icons}>       
            <FcGoogle onClick={handleGoogleLogin} style={{ cursor: 'pointer' }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
