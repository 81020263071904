import React from 'react'
import style from "./LibraryCard.module.css"
import { useSelector } from 'react-redux';
import { ICONS } from '../../Constants/Icons/icons';
import { COLORS } from '../../Constants/Colors/colors';
import { FONT_SIZES } from '../../Constants/Font/font_sizes';
import { Link } from 'react-router-dom';

const LibraryCard = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    return (
        <Link to="/manage-videos">
            <div className={style.cardContainer} style={{ direction: lang === 'ar' ? "rtl" : "ltr", backgroundColor: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>
                <div className={style.cardHeader}>
                    <div>
                        <h3 style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT, fontSize: FONT_SIZES.MEDIUM }}>Library Name</h3>
                        <p>5 {lang === 'ar' ? "فيديوهات" : "videos"}</p>
                    </div>
                    <div className={style.moreIcon} style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>
                        {ICONS.More}
                    </div>
                </div>

                <div className={style.details}>
                    <div>
                        <p>{lang === 'ar' ? "التخزين" : "Storage"}</p>
                        <p style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>442.59 MB</p>
                    </div>

                    <div>
                        <p>Traffic</p>
                        <p style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>72.68 MB</p>
                    </div>
                </div>
                <div className={style.basic}>
                    <div style={{ fontSize: FONT_SIZES.SMALL }}>Basic DRM</div>
                </div>
            </div>
        </Link>
    )
}

export default LibraryCard