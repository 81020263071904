import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import style from "./VerifyEmail.module.css";
import { api_url } from './../../../Constants/base_url';
import CustomInput from './../../CustomInput/CustomInput';
import CustomButton from './../../../Shared/CustomButton/CustomButton';
import { COLORS } from './../../../Constants/Colors/colors';
import { useSelector } from 'react-redux';
import { signupSchema } from './../../../Schema/signup.schema';
import { zodResolver } from '@hookform/resolvers/zod';

const VerifyEmail = ({ email, onSuccess }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const schema = signupSchema(lang);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });
  
  const { mutate, isError, error } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}auth/verify-email`, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: (data) => {
      console.log("Verification successful", data);
      onSuccess();
    },
    onError: (error) => {
      console.error("Verification failed:", error);
    },
  });

  const onSubmit = (data) => {
    data.email = email;
    mutate(data);
  };

  if (isError) {
    console.error("Axios error", error);
  }

  return (
    <div className={style.container}>
      <h2 style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_COLOR }}>{lang === 'ar' ? "التحقق من البريد الإلكتروني" : "Verify Your Email"}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          placeholder={lang === 'ar' ? "رمز التحقق" : "Verification Code"}
          register={register}
          name='verification_code'
          errors={errors}
        />
        <CustomButton type="submit">{lang === 'ar' ? "التحقق" : "Verify"}</CustomButton>
      </form>
    </div>
  );
};

export default VerifyEmail;
