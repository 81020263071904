import React, { useState } from 'react'
import style from "./Collections.module.css"
import { ICONS } from '../../../Constants/Icons/icons'
import { FiSearch } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { COLORS } from '../../../Constants/Colors/colors';
import { FONT_SIZES } from '../../../Constants/Font/font_sizes';
import CustomSelect from '../../../Components/CustomSelect/CustomSelect';
import CollectionCard from '../../../Components/CollectionCard/CollectionCard';
import SideSection from '../../../Shared/SideSection/SideSection';
import CollectionVideos from './CollectionVideos/CollectionVideos';

const Collections = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    const [selectedCollection, setSelectedCollection] = useState(false);


    return (
        <>
            <div>
                <div className={style.header}>
                    <CustomSelect>
                        <li style={{ borderColor: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>Sort by Date</li>
                        <li>Sort by Title</li>
                    </CustomSelect>
                </div>
                <div className={style.collectionsContainer}>
                    <CollectionCard
                        onClick={() => setSelectedCollection(true)} //by id
                    />
                </div>
            </div>
            <SideSection isAppear={selectedCollection} onClose={() => setSelectedCollection(false)}>
                <CollectionVideos/>
            </SideSection>
        </>
    )
}

export default Collections

