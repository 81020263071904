import React from 'react'
import style from "./Videos.module.css"
import CustomSelect from './../../../Components/CustomSelect/CustomSelect';
import { useSelector } from 'react-redux';
import { COLORS } from './../../../Constants/Colors/colors';
import VideoCard from './../../../Components/VideoCard/VideoCard';

const Videos = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    return (
        <div>
            <div className={style.header}>
                <CustomSelect>
                    <li style={{ borderColor: isDarkMode ? COLORS.SECOND_BG_COLOR.DARK : COLORS.SECOND_BG_COLOR.LIGHT }}>Sort by Date</li>
                    <li>Sort by Title</li>
                </CustomSelect>
            </div>
            <div className={style.videosContainer}>
                <VideoCard />
                <VideoCard />
                <VideoCard />
                <VideoCard />
                <VideoCard />
                <VideoCard />
            </div>
        </div>
    )
}

export default Videos