import React, { useState } from 'react'
import style from "./ManageVideos.module.css"
import { FaAngleDown } from "react-icons/fa6";
import { COLORS } from '../../Constants/Colors/colors';
import Overview from './Overview/Overview';
import Collections from './Collections/Collections';
import Videos from './Videos/Videos';
import { ICONS } from '../../Constants/Icons/icons';
import { useSelector } from 'react-redux';
import { FONT_SIZES } from '../../Constants/Font/font_sizes';
import CustomAddButton from '../../Components/CustomAddButton/CustomAddButton';
import Modal from '../../Shared/Modal/Modal';

const ManageVideos = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    const [tab, setTab] = useState("all");
    const tabs = { tab1: "all", tab2: "collections", tab3: "videos" };

    const [uploadVideo, setUploadVideo] = useState(false);
    const [addCollection, setAddCollection] = useState(false);


    return (
        <>
            <main className='pageSection'>
                <div className={style.pageHeader}>
                    <h2 style={{ fontSize: FONT_SIZES.LARGE, color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_COLOR }}>
                        Video Name
                        <span style={{ fontSize: FONT_SIZES.REGULAR, borderColor: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_COLOR }}>6</span>
                    </h2>
                    <div>
                        {(tab === tabs.tab1 || tab === tabs.tab3) && <CustomAddButton onClick={() => setUploadVideo(true)}>{lang === 'ar' ? "تحميل فيديو" : "Upload a Video"}</CustomAddButton>}
                        {tab === tabs.tab2 && <CustomAddButton onClick={() => setAddCollection(true)}>{lang === 'ar' ? "اضف مجموعة" : "Add Collection"}</CustomAddButton>}

                        <div className={style.moreIcon}>
                            {ICONS.More}
                        </div>
                    </div>
                </div>
                <div className={style.tabsContainer}>
                    <div className={style.tabs}>
                        <ul>
                            <li
                                onClick={() => setTab(tabs.tab1)}
                                className={tab === tabs.tab1 ? style.active : ""}
                            >
                                All
                            </li>
                            <div>
                                {tab === tabs.tab2 && <li
                                    onClick={() => setTab(tabs.tab2)}
                                    className={tab === tabs.tab2 ? style.active : ""}
                                >
                                    Collection
                                </li>}
                                {tab === tabs.tab3 && <li
                                    onClick={() => setTab(tabs.tab3)}
                                    className={tab === tabs.tab3 ? style.active : ""}
                                >
                                    Videos
                                </li>}
                            </div>
                        </ul>
                    </div>
                    <div className={style.page}>
                        {tab === tabs.tab1 && <Overview onClickCollections={() => setTab(tabs.tab2)} onClickVideos={() => setTab(tabs.tab3)} />}
                        {tab === tabs.tab2 && <Collections />}
                        {tab === tabs.tab3 && <Videos />}
                    </div>
                </div>
            </main>
            {uploadVideo && <Modal isAppear onClose={()=>setUploadVideo(false)}/>}
            {addCollection && <Modal isAppear onClose={()=>setAddCollection(false)}/>}
        </>
    )
}

export default ManageVideos