import React from 'react'
import style from "./Overview.module.css"
import CustomHeader from '../../Components/CustomHeader/CustomHeader'
import { COLORS } from '../../Constants/Colors/colors'
import { useSelector } from 'react-redux';
import { FaLongArrowAltRight } from "react-icons/fa";
import { FONT_SIZES } from '../../Constants/Font/font_sizes';
import ChartCard from '../../Components/ChartCard/ChartCard';
import LineChart from '../../Components/LineChart/LineChart';


const Overview = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  return (
    <main className='pageSection'>
      <section>
        <CustomHeader size={30}>
          <div className={style.header}>
            <p>Statistcs</p>
            <h3 style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>Last 24 hours</h3>
          </div>
        </CustomHeader>
        <div className={style.subState}>
          <ChartCard />
          <ChartCard />
          <ChartCard />
        </div>
      </section>

      <section className={style.sec}>
        {/* <div>
          <h4>Bandwidth Served</h4>
          <div>
            <span>
              <p>Total Bandwidth</p>
              <h3>75.19 MB</h3>
            </span>
            <span>
              <p>Cached Bandwidth</p>
              <h3>12.08 MB</h3>
            </span>
            <span>
              <p>Uncached Bandwidth</p>
              <h3>63.11 MB</h3>
            </span>
          </div>
        </div> */}

        <div>
          <p style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>Bandwidth Served</p>

          <div className={style.chart}><LineChart /></div>

        </div>
        <div>
          <p style={{ color: isDarkMode ? COLORS.MAIN_FONT_COLOR.DARK : COLORS.MAIN_FONT_COLOR.LIGHT }}>Requests Served</p>
          <div className={style.chart}><LineChart /></div>
        </div>
      </section>
    </main>
  )
}

export default Overview