import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderComponent = ({children}) => {
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: "var(--light-gray)",
          transition: "all 0.3s ease",
        }}
      ></div>
    ),
    appendDots: (dots) => (
      <div style={{
        position: 'absolute',
        bottom: "-40px"
        }}>
        <ul>
          {dots}
        </ul>
      </div>
    ),
    dotsClass: `slick-dots custom-dots`,
  };

  return (
    <div style={{ position: "relative", paddingBottom: "50px" }}>
      <Slider {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default SliderComponent;